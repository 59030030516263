import * as React from 'react';
import { graphql } from 'gatsby';
import tw from 'twin.macro';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';
import { seoData } from '../data/seo';
import { defaultLangKey } from '../data/languages';
import Title from '../components/title';
import ContentWidth from '../components/contentWidth';

type QaNode = {
  title: string;
  content: string;
};

type Props = {
  location: Location;
  data: {
    qa: {
      edges: [
        {
          node: QaNode;
        }
      ];
    };
  };
};

function QA({ location, data: { qa } }: Props) {
  return (
    <Layout
      lang={defaultLangKey}
      location={location}
      pathnameWithoutLang={models.pages!.qa.path}
    >
      <SEO
        featuredImage={seoData.qa.featuredImage?.[defaultLangKey]}
        title={seoData.qa.title[defaultLangKey]}
        description={seoData.qa.description[defaultLangKey]}
        lang={defaultLangKey}
        pathname={location.pathname}
      />
      <ContentWidth>
        <Title title={`よくあるお問い合わせ`} />
        <section css={tw`max-w-screen-md mx-auto`}>
          {qa.edges.map((item, index) => (
            <article
              key={index}
              css={tw`flex flex-col first:pt-6 first:mt-6 md:first:pt-10 md:first:mt-10 pb-6 md:pb-10 mb-6 md:mb-10 border-b last:border-none border-gray-100`}
            >
              <div css={tw`bg-gray-100 p-1 rounded`}>
                <header css={tw`flex items-center leading-none my-4 px-4`}>
                  <span
                    css={tw`text-3xl md:text-4xl mr-3 text-title italic font-medium hidden md:inline`}
                  >
                    Q{index + 1}.
                  </span>
                  <span
                    css={tw`text-base font-bold leading-relaxed md:leading-loose`}
                  >
                    {item.node.title}
                  </span>
                </header>
                <div
                  css={tw`md:flex w-full p-4 md:p-5 bg-white rounded overflow-hidden`}
                >
                  <div
                    css={tw`flex items-center font-bold leading-relaxed md:leading-loose text-sm md:text-base`}
                  >
                    {item.node.content}
                  </div>
                </div>
              </div>
            </article>
          ))}
        </section>
      </ContentWidth>
    </Layout>
  );
}

export const qaQuery = graphql`
  query {
    qa: allQaJson {
      edges {
        node {
          title
          content
        }
      }
    }
  }
`;

export default QA;
