import React from 'react';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { v4 as uuid } from 'uuid';

type Props = {
  title: string | string[];
  oneLiner?: boolean;
  size?: 'small' | 'medium' | 'large';
  textAlign?: 'center' | 'left' | 'right';
  icon?: IconProp;
};

const Title = ({ title, oneLiner, size, textAlign, icon }: Props) => {
  let css;
  let iconCss;
  let wrapper = tw``;
  let Heading = `h1`;

  switch (size) {
    case 'small':
      wrapper = tw`mb-4`;
      css = tw`text-lg md:text-2xl text-center font-bold`;
      iconCss = tw`text-base md:text-lg mr-3`;
      Heading = `h3`;
      break;
    case 'medium':
      wrapper = tw`mb-6`;
      css = tw`text-xl md:text-3xl text-center font-bold`;
      iconCss = tw`text-lg md:text-xl mr-3`;
      Heading = `h2`;
      break;
    case 'large':
    default:
      wrapper = tw`mb-12`;
      css = tw`text-2xl md:text-4xl text-center font-bold`;
      iconCss = tw`text-xl md:text-2xl mr-3`;
      break;
  }

  return (
    <div css={wrapper}>
      <div css={!!icon ? tw`flex items-center` : tw``}>
        {!!icon ? (
          <span>
            <FontAwesomeIcon icon={icon} css={iconCss} />
          </span>
        ) : null}
        <Heading css={css} style={{ textAlign: textAlign ?? 'center' }}>
          {Array.isArray(title)
            ? title.map((item, index) => {
                return (
                  <span key={`title-${index}-${uuid()}`}>
                    {item}
                    {oneLiner ? null : <br />}
                  </span>
                );
              })
            : title}
        </Heading>
      </div>
    </div>
  );
};

export default Title;
